import { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@saatva-bits/pattern-library.components.button'
import { useAttributeOptions, useProductState, useAddToCartData } from '@saatva-bits/pattern-library.modules.selection'
import { Loader } from '@saatva-bits/pattern-library.components.loader'
import { useSharedCartService } from '@saatva-bits/pattern-library.modules.cart'

import SoldOutMessage from '../SoldOutMessage'
import QuantityInput from './partials/QuantityInput'

import styles from './AddToCartButton.module.scss'



/**
 *
 * @param {{
 *  productCode: string,
 *  onAddToCart: () => void,
 * }} props
 * @returns
 */
const AddToCartButton = ({
    children,
    productCode,
    hasQtySelector,
    onAddToCart
}) => {

    const primaryProductState = useProductState(productCode, ['category', 'inStock', 'quantity', 'price', 'sku', 'bundledVariants'])
    
    let { items: itemsForAddToCart } = useAddToCartData(productCode)
    if (primaryProductState.bundledVariants && primaryProductState.bundledVariants.length) {
        itemsForAddToCart = primaryProductState.bundledVariants.map(product => {
            return {
                sku: product.sku,
                quantity: Number(product.quantity),
            }
        })
    }

    const totalQuantity = itemsForAddToCart.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.quantity
    }, 0)

    const quantityAttribute = useAttributeOptions(productCode, 'quantity')
    const { addItemToCart } = useSharedCartService()

    const [isLoading, setIsLoading] = useState(false)
    const [buttonText, setButtonText] = useState(children)

    const dimension = `${primaryProductState.category} plp`
    
    const handleAddToCartButton = async (items) => {
        setIsLoading(true)
        await addItemToCart(items, null, dimension)
        const itemsToCart = totalQuantity > 1 ? 'Items to Cart' : 'Item to Cart'
        setButtonText(`Added ${totalQuantity} ${itemsToCart}`)
        onAddToCart && onAddToCart()
        setIsLoading(false)
    }

    if (primaryProductState.inStock) {
        return (
            <div className={styles['container']}>
                {hasQtySelector && quantityAttribute &&
                    <QuantityInput quantityAttribute={quantityAttribute} dataSelector='QtySelector' />
                }
                <Button
                    type="button"
                    kind="primary"
                    className={styles['addButton']}
                    onClick={() => {
                        handleAddToCartButton(itemsForAddToCart)
                    }}
                >
                    {isLoading && <Loader className={styles['loader']} itemClassName={styles['loaderDots']} />}
                    {buttonText}
                </Button>
            </div>
        )
    }
    return <SoldOutMessage className={`${styles['addButton']} ${styles['addButton-soldOut']}`} />
}

AddToCartButton.propTypes = {
    productCode: PropTypes.string,
    onAddToCart: PropTypes.func
}

export default AddToCartButton
