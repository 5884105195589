import classNames from 'classnames'
import PropTypes from 'prop-types'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { usePageProps } from '@/hooks/usePageProps'

import styles from './ProductSummary.module.scss'

const PriceStrikeThrough = ({ lowestPrice, lowestPriceDiscount, className, discountClassName }) => {
    const priceContent = formatCurrency(lowestPrice)
    const hasDiscount = lowestPrice !== lowestPriceDiscount
    const discountContent = hasDiscount && formatCurrency(lowestPriceDiscount)
    const mainPrice = hasDiscount ? discountContent : priceContent

    return (
        <div className={classNames(styles.price, className)}>
            {mainPrice}
            {discountContent && (
                <span className={classNames(styles.strikeThrough, discountClassName)}>
                    {priceContent}
                </span>
            )}
        </div>
    )
}

PriceStrikeThrough.propTypes = {
    className: PropTypes.string,
    discountClassName: PropTypes.string,
    lowestPrice: PropTypes.number.isRequired,
    lowestPriceDiscount: PropTypes.number.isRequired
}

const ProductSummary = ({
    title,
    subtitle,
    product,
    promotionDeps,
    selectionDeps,
    // selectedSizes = []
}) => {
    const { useGetDiscounts } = promotionDeps
    const {
        useProductState
        // useAttributeOptions
    } = selectionDeps
    const { getDiscounts } = useGetDiscounts()
    const { products, saleData } = usePageProps()

    const { price, sku, variantUrl, bundledVariants, category } = useProductState(product.productCode, ['price', 'sku', 'variantUrl', 'bundledVariants', 'category'])

    let productsForDiscount =  [{
        sku,
        parentSku: product.productCode,
        quantity: 1,
        price
    }]
    
    if (category === 'Bundles') {
        let fullProduct = null
        if (saleData && saleData.bundles) {
            fullProduct = saleData.bundles.products.find(parent => parent.productCode === product.productCode)
        } else {
            fullProduct = products.find(parent => parent.productCode === product.productCode)
        }

        if (fullProduct.bundledProducts && fullProduct.bundledProducts.length > 0) {
            productsForDiscount = bundledVariants.map(bundledVariant => {
                const bundleProduct = fullProduct.bundledProducts.find(product => product.productCode === bundledVariant.productCode)
                const actualVariant = bundleProduct.variants.find(variant => variant.sku === bundledVariant.sku)
        
                return {
                    sku: bundledVariant.sku,
                    parentSku: bundledVariant.productCode,
                    price: actualVariant.price,
                    quantity: bundledVariant.quantity
                }
            })
        }    
    }

    const { fullPrice, finalPrice } = getDiscounts(productsForDiscount)

    return (
        <section className={styles.container}>
            <h2 className={`t-heading1 t-color ${styles.title}`}>
                <a href={variantUrl}>{title}</a>
            </h2>
            <div className={styles.subtitle}>
                {subtitle}
            </div>
            <PriceStrikeThrough
                lowestPrice={fullPrice}
                lowestPriceDiscount={finalPrice}
                discountClassName={styles.strikeThrough}
            />
        </section>
    )
}

ProductSummary.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    selectedSizes: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
    })),
    /** Interface for promo context dependencies */
    promotionDeps: PropTypes.shape({
        useGetDiscounts: PropTypes.func,
        applyDiscounts: PropTypes.func,
    }),
    /** Interface for selection context dependencies */
    selectionDeps: PropTypes.shape({
        useProductState: PropTypes.func,
        useAttributeOptions: PropTypes.func,
    }),
    product: PropTypes.shape({
        productCode: PropTypes.string,
        lowestPrice: PropTypes.number,
        highestPrice: PropTypes.number,
        configurableAttributes: PropTypes.arrayOf(PropTypes.string),
        variants: PropTypes.arrayOf(
            PropTypes.shape({
                sku: PropTypes.string,
                productCode: PropTypes.string,
                category: PropTypes.string,
                price: PropTypes.number,
            })
        )
    })
}

export default ProductSummary
