import PropTypes from 'prop-types'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'

import { getSwatches, getImages as getImagesFunction } from '@/utils/product'
import { getBadgeText } from '@/utils/discount'

import styles from './DetailProductTileSlider.module.scss'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const commonImageProps = {
    widths: { mobile: 348 },
    lazyLoadOffset: 200,
    isUniversal: true,
    imgixDomain: IMGIX_URL
}

const DetailProductTileSlider = ({
    products,
    title,
    sectionClassName,
    titleClassName,
    dataSelector,
    isCart = false,
    simpleSliderProps = {}
}) => {
    const { getDiscounts } = useGetDiscounts()

    if (isEmpty(products)) {
        return null
    }

    const sliderContent = products.map(product => {
        const { lowestPrice, highestPrice } = product

        const baseProduct = {
            sku: product.sku,
            parentSku: product.parentSku || product.sku,
            category: product.category,
            quantity: 1,
            isPrimaryProduct: true
        }

        const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
        const { finalPrice: highestPriceDiscount, discountAmount: highestDiscountAmount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

        const priceRange = {
            fullPriceMin: lowestPrice,
            fullPriceMax: highestPrice,
            finalPriceMin: lowestPriceDiscount || 0,
            finalPriceMax: highestPriceDiscount || 0,
        }

        const title = product.title || product.content.title
        const subtitle = product.subtitle || product.content.subtitle

        const swatches = getSwatches(product.options, product.content.defaultSwatch, product.variants.length)
        const getImages = getImagesFunction(product.productCode, title, product.category.toLowerCase(), commonImageProps)
        const badgeText = getBadgeText(highestDiscountAmount, 'exact')

        return(
            <DetailProductTile
                key={product.sku}
                containerClassName={styles.tile}
                title={{ text: title, href: product.url, titleClassName: styles.title }}
                subtitle={{ text: subtitle, className: styles.subtitle }}
                imageHref={product.url}
                priceRange={priceRange}
                swatches={swatches}
                getImages={getImages}
                imageBadgeLeft={badgeText ? { text: badgeText, kind: 'promo', position: 'topLeft' } : undefined}
            />
        )
    })

    const leftButtonClasses = classNames(styles.buttons, styles.leftButton)
    const rightButtonClasses = classNames(styles.buttons, styles.rightButton)

    const sliderProps = {
        isCart,
        sliderLabel: dataSelector,
        infinite: true,
        pageDotsAndNumbers: true,
        dotsClassName: styles.paginationDots,
        leftButtonClassName: leftButtonClasses,
        rightButtonClassName: rightButtonClasses,
        ...simpleSliderProps
    }

    const sectionClassNames = classNames('section', styles.section, sectionClassName)
    const titleClassNames = classNames('t-heading2 t-color', styles.sectionTitle, titleClassName )

    return(
        <section className={sectionClassNames} data-selector={dataSelector}>
            <div className="container">
                {title &&
                    <h2 className={titleClassNames}>
                        {title}
                    </h2>
                }
                <div className={styles.mobileSlider}>
                    <SimpleSlider
                        shownSize={1}
                        overflow={'visible'}
                        {...sliderProps}
                    >
                        { sliderContent }
                    </SimpleSlider>
                </div>
                <div className={styles.defaultSlider}>
                    <SimpleSlider
                        shownSize={3}
                        overflow={'hidden'}
                        {...sliderProps}
                    >
                        { sliderContent}
                    </SimpleSlider>
                </div>
            </div>
        </section>
    )
}

DetailProductTileSlider.propTypes = {
    products: PropTypes.array.isRequired,
    title: PropTypes.string,
    sectionClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    dataSelector: PropTypes.string,
    isCart: PropTypes.bool,
    hasSwatches: PropTypes.bool,
}

export default DetailProductTileSlider
